.logoBg{
    // background-color: #fef3e4;
    // background-color: #4b5a8d;
}
.logoSize{
    width: 100%;
    padding: 19px;
}

// sidebar dashboard.
.bg-sidebar{
    background-color: #f1f2f5;
    height: calc(100vh - 0.6rem);
    padding: 30px 0px 30px 0px;
    // border-top-left-radius: 12px;
    // border-bottom-left-radius: 12px;
    overflow: auto;
    position: relative;
    border-radius: 12px;
}
.bg-sidebar::-webkit-scrollbar{
    display: none;
}
.sidebar_listenu li{
    // height: 48px;
    // line-height: 48px;
}
.sidebar_listenu li a{
    padding: 12px;
    min-width: 200px;
    height: inherit;
}
.sidebar_listenu li a:hover{
    background-color: rgb(206, 206, 206);
    border-radius: 9px;
}
.sidebar_listenu li .active{
    background-color: #5d87ff;
    border-radius: 9px;
    color: white !important;
}
.hiden_menu{
    
    border: 1px solid #e9e9e9;
    border-radius: 3px;
}
.hiden_menu:hover{
    opacity: 1;
}





