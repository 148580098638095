.react-datepicker__header {
   font-family: view360, sans-serif !important;
}
.react-datepicker__week{
   font-family: view360, sans-serif !important;
}
.react-datepicker-popper{
   max-width: 200px;
}
.react-datepicker-wrapper .react-datepicker__input-container button::after{
    background-color: #8c9097 !important;
    font-weight: 500;
    height: 17.5px;
    width: 17.5px;
    padding: 0px; 
    font-size: 15px;
    opacity: 0.7;
}
.react-datepicker__close-icon{
   right: 3px
}
