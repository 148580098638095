.row{
    --bs-gutter-x: 1rem !important;
}
.card.custom-card{
    box-shadow: 1px 2px 6px 1px rgba(56, 65, 74, 0.15);
}
.wrapper_container{
    position: relative;
    top: 0px;
    height: 100vh;
}
.button-active{
    background: #f4edea;
}
.modal-content{
    height: calc(100vh - 3rem);
    overflow-y: scroll;
}
.modal-content::-webkit-scrollbar{
    display: none;
}
.w-39{
    width: 39px;
    height: 39px;
}
.alert-warning-1{
    background-color: #fcd891;
}
.btn-check:active + .btn-light, .btn-check:checked + .btn-light, .btn-light.active, .btn-light.show, .btn-light:active {
    background-color: rgb(244 237 234) !important;
}

/** ============== wrapper_menu_left ============ */ 
@media (min-width: 600px) {
    .wrapper_sidemenu_left {
        display: none;
    }
    .wrapper_sidemenu_left{
        width: 284px;
        position: fixed;
        height: 100%;
        padding-left: 16px;
        transition: all 0.3s ease;
        overflow: hidden;
    }
}
@media (min-width: 1002px) {
    .wrapper_sidemenu_left {
        display: block;
    }
}

/** ===Sidemenu pc. ===*/ 
.wrapper_sidemenu_left.compact {
    height: calc(100vh - 0.1rem);
    top: 16px;
    width: 284px; /* Kích thước đầy đủ khi mở rộng */
    transform: translateX(0);
}

.wrapper_sidemenu_left.more {
    height: calc(100vh - 0.1rem);
    top: 16px;
    width: 100px; /* Kích thước thu gọn */
    transform: translateX(0);
}

.side-menu-content {
    height: 100%;
    transition: all 0.3s ease;
}

.wrapper-bg{
    // background: #f2e4dd;
    background: white;
    height: calc(100vh - 1.9rem);
    width: 100%;
    overflow: auto;
    // border: 1px solid #ebebeb;
}
.wrapper-bg::-webkit-scrollbar{
    display: none;
}

.menuSidebar{
    height: 45px;
    padding: 0px 12px 0px 12px;
    color: rgb(30, 22, 78) !important;
}
.listItem-sideMenu li{
    padding: 0px 18px 12px 18px;
}
.listItem-sideMenu li .menuSidebar:hover{
    background: rgb(244 237 234) !important;
    color: rgb(30, 22, 78) !important;
    border-radius: 12px;
    height: 45px;
}

.activeMenu{
    background: rgb(244 237 234) !important;
    border-radius: 6px;
    color: rgb(30, 22, 78) !important;
    font-weight: 500;
    height: 45px;
}
.activeSuccess{
    background: #1bc88a;
    color: white !important;
}



// sidebar_dashboard 2
.sidebar-scroll{
    overflow: auto;
    height: calc(100vh - 1rem);
}
.sidebar-scroll::-webkit-scrollbar{
    display: none;
}
.sidebarMenu .active{
    background-color: rgb(255, 214, 10);
    border-radius: 9px;
    color: white !important;
}
.sidebarMenu li a:hover{
    background-color: rgb(255, 214, 10) !important;
    border-radius: 9px;
    color: white !important;
}

// Tạo độ lõm cho menu đoạn giữa avatar và list menu
.receipt-style {
    margin: 0px 4px;
    position: relative;
}

.receipt-style::before, .receipt-style::after {
    content: "";
    position: absolute;
    width: 35px;
    height: 35px;
    left: -21px;
    top: -16px;
    border-radius: 50%;
    background: #f4edea;
}

.receipt-style::before {
  left: -21px; /* Đặt phần lõm bên trái */
  top: 0; /* Căn giữa theo chiều dọc */
  transform: translateY(-50%); /* Căn giữa phần lõm theo chiều dọc */
}

.receipt-style::after {
    right: -21px;
    left: unset;
}
/** ==*Sidemenu pc===*/

/** ============== *wrapper_menu_left ============ */ 




/** ============= wrapper_right ==============*/ 
@media (min-width: 1002px) {
    .wrapper_content_right {
        width: calc(100% - 284px) !important;
        transition: all 0.3s ease;
    }
}
.wrapper_content_right.compact {
     width: calc(100% - 284px) !important;
    transform: translateX(0);
}

.wrapper_content_right.more {
     width: calc(100% - 100px) !important;
    transform: translateX(0);
}

.wrapper_content_right{
    overflow: auto;
    position: relative;
    float: right;
    max-height: 100%;
    width: 100%;
}
.content_right{
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin: 0px 16px;
    min-height: calc(100% - 1.9rem);
    display: flex;
    flex-direction: column;
}
.content_layout_right{
    -webkit-box-flex: 1;
    flex-grow: 1;
    overflow: hidden;
    height: calc(100vh);
}


// Header
.wrapper_header{
    display: flex;
    gap: 8px;
    -webkit-box-align: center;
    align-items: center;
    margin: 12px 0px 12px;
}
.wrapper_header_children{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    float: right;
    margin-left: auto;
    gap: 12px;
}


// content.
.wrapper_content {
    background: white;
    padding: 1rem;
    border-radius: 1rem;
    height: calc(100vh - 4.9rem);
}
.wrapper_module_content{
    overflow: scroll;
    height: calc(100vh - 6rem);
}
.wrapper_module_content::-webkit-scrollbar{
    display: none;
}

// Wapper_content_children.
.wrapper_content_children{
    height: calc(100vh - 6rem);
}
.wrapper_content_children .heightScroll{
    overflow-y: auto;
    height: calc(100vh - 9rem);
}
.wrapper_content_children .heightScroll::-webkit-scrollbar{
    display: none;
}
/** ============= *wrapper_right ==============*/ 



/** ====== sweet alert ====== */
div:where(.swal2-container) div:where(.swal2-popup){
    border-radius: 1rem !important;
}
/** ====== *sweet alert ====== */