.dateInput{
    border: 2px solid rgb(244 237 234) !important;
    border-radius: 9px;
    padding: 9px;
    // margin: 9px 0;
    // height: 37.1px;
}
.fontType{
    // font-family: "Circular", -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
    font-family: view360, sans-serif !important;
}
