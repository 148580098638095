// Content bot
.chat-item-bot{
    display: flex;
}
.chat-item-bot .chat-list-inner{
    display: flex;
}

// Content user.
.chat-item-user{
    display: flex;
    justify-content: end;
}
.chat-item-user .chat-list-inner{
    display: flex;
}
.chat-list-inner-user{
    background: #f4edea;
    padding: 12px;
    border-radius: 1rem;
}


// đầu vào dữ liệu
.break-word {
    //word-wrap: break-word; /* Cho phép ngắt dòng khi từ quá dài */
    //word-break: break-all; /* Ngắt từ nếu không còn đủ chỗ */
    //white-space: normal; /* Cho phép văn bản xuống dòng */
    resize: none; // bỏ thuộc tính kéo chiều cao
    border: none;
}