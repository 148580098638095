// pc
.wrapper_left, .wrapper_right{
    height: 100%
}
.wrapper_left{
    width: 40%;
    background-color: #f1c25e;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}
.wrapper_right{
    width: 60%;
    background-color: #f4ede9;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

// mobile.
.card-body-mobile{
    border: 1px solid white;
    margin: 1rem;
    border-radius: 1rem;
    overflow: hidden;
}



// payment list.
.payment-list{
    background-color: #f4ede8;
}