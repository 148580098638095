
body{
    background-color: #f4edea!important;
    color: rgb(30, 22, 78) !important;
}

.bg-logo{
    background-color: #ff6a15 !important;
}
// Dùng chung
.w-20{
    width: 20%;
}
.w-80{
    width: 80%
}
.carousel-app::-webkit-scrollbar{
    overflow: hidden;
}
.table-responsive-customer tr th{
    font-size: 16px;
}
.table-responsive-customer tr td{
    font-size: 16px;
}
.table-responsive-customer tr td{
    white-space: normal; /* Cho phép xuống dòng bình thường */
}

// Dropdown menu tippy.
.dropdown-menu-tippy{
    border-color: var(--default-border);
    box-shadow: 0 1rem 1.125rem rgba(169, 169, 169, 0.15);
    font-size: 0.875rem;
    color: var(--default-text-color);
    background-color: var(--custom-white);
    padding: 0;
    border-radius: 12px;
    min-width: 200px;
}
.dropdown-menu-tippy li{
    cursor: pointer;
}

// page Dashboard 1
.wrapper_right{
    height: calc(100vh - 0.5rem);
    overflow: auto;
}
.wrapper_container{
    height: 84vh;
}

// Kich thuoc man hinh.
.wrapper_ground{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.list_items{
    flex-grow: 1;
    flex-basis: 200;
}

// date.
.react-datepicker{
    width: 200px;
    border-radius: 1rem;
    overflow: hidden;
}

// search data.
.search-data{
    width: 36.4px;
    height: 36.4px;
    flex-grow: 0;
}
.active-search{
    width: 100% !important;
    transition: width 5s;
    
}


 
